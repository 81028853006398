<template>
  <v-list
    :shaped="isShaped"
    dense
  >
    <v-list-item-group
      active-class="active"
      color="primary"
    >
      <ListItem
        v-if="showDashboardSection"
        title="Dashboard"
        route="/people/dashboard"
        icon="fas fa-chart-pie"
      />
      <ListItem
        v-if="showAgenciesSection"
        title="Agências"
        route="/people/agencies"
        icon="fas fa-store"
      />
      <ListItem
        v-if="showClientsSection"
        title="Clientes"
        route="/people/clients"
        icon="fas fa-grin-stars"
      />
      <ListItem
        v-if="showSearchClientsSection"
        title="Consultar Clientes"
        route="/people/search-clients"
        icon="fas fa-search"
      />
      <ListItem
        v-if="showCompaniesSection"
        title="Empresa"
        route="/people/companies"
        icon="fas fa-building"
      />
      <ListItem
        v-if="showProfilesSection"
        title="Tipos de Perfil"
        route="/people/profiles"
        icon="fas fa-id-card"
      />
      <ListItem
        v-if="showUsersSection"
        title="Usuários"
        route="/people/users"
        icon="fas fa-users"
      />
      <ListItem
        v-if="showSellersSection"
        title="Vendedores"
        route="/people/sellers"
        icon="fas fa-comments-dollar"
      />
    </v-list-item-group>
  </v-list>
</template>

<script>
import ListItem from './DefaultListItem.vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    isShaped: {
      type: Boolean,
      default: () => true,
    },
  },
  components: {
    ListItem,
  },
  computed: {
    ...mapGetters('people', [
      'showUsersSection',
      'showSellersSection',
      'showProfilesSection',
      'showCompaniesSection',
      'showSearchClientsSection',
      'showClientsSection',
      'showAgenciesSection',
      'showDashboardSection',
    ]),
  },
}
</script>
