<template>
  <v-list
    :shaped="isShaped"
    dense
  >
    <v-list-item-group
      active-class="active"
      color="primary"
    >
      <ListItem
        v-if="showDashboardSection"
        title="Dashboard"
        route="/comercial/dashboard"
        icon="fas fa-chart-pie"
      />
      <ListItem
        v-if="showCrmSection"
        title="Atividades CRM"
        route="/comercial/crm"
        icon="fas fa-headset"
      />
      <ListItem
        v-if="showProductsSearchSection"
        title="Consultar Produtos"
        route="/comercial/products"
        icon="fas fa-search"
      />
      <ListItem
        v-if="showBudgetsSection"
        title="Orçamentos"
        route="/comercial/budgets"
        icon="fas fa-hand-holding-usd"
      />
      <ListItem
        v-if="showOrdersSection"
        title="Pedidos"
        route="/comercial/orders"
        icon="fas fa-vote-yea"
      />
      <ListItem
        v-if="showOrderProductsSection"
        title="Produtos do pedido"
        route="/comercial/order-products"
        icon="fas fa-truck-loading"
      />
    </v-list-item-group>
  </v-list>
</template>

<script>
import ListItem from './DefaultListItem.vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    isShaped: {
      type: Boolean,
      default: () => true,
    },
  },
  components: {
    ListItem,
  },
  computed: {
    ...mapGetters('comercial', [
      'showCrmSection',
      'showProductsSearchSection',
      'showBudgetsSection',
      'showOrdersSection',
      'showOrderProductsSection',
      'showDashboardSection',
    ]),
  },
}
</script>
