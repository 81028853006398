<template>
  <v-list
    :shaped="isShaped"
    dense
  >
    <v-list-item-group
      active-class="active"
      color="primary"
    >
      <ListItem
        v-if="showDashboardSection"
        title="Dashboard"
        route="/stock/dashboard"
        icon="fas fa-chart-pie"
      />
      <ListItem
        v-if="showCategoriesSection"
        title="Categorias"
        route="/stock/categories"
        icon="fas fa-th"
      />
      <ListItem
        v-if="showCompositionSection"
        title="Composição"
        route="/stock/composition"
        icon="fas fa-puzzle-piece"
      />
      <ListItem
        v-if="showColorsSection"
        title="Cores"
        route="/stock/colors"
        icon="fas fa-palette"
      />
      <ListItem
        v-if="showFactorSection"
        title="Fator"
        route="/stock/factor"
        icon="fas fa-percentage"
      />
      <ListItem
        v-if="showVendorsSection"
        title="Fornecedores"
        route="/stock/vendors"
        icon="fas fa-people-carry"
      />
      <ListItem
        v-if="showPrintsSection"
        title="Gravações"
        route="/stock/print"
        icon="fas fa-stamp"
      />
      <ListItem
        v-if="showNcmSection"
        title="NCM"
        route="/stock/ncm"
        icon="fas fa-tags"
      />
      <ListItem
        v-if="showProductsSection"
        title="Produtos"
        route="/stock/products"
        icon="fas fa-box"
      />
    </v-list-item-group>
  </v-list>
</template>

<script>
import ListItem from './DefaultListItem.vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    isShaped: {
      type: Boolean,
      default: () => true,
    },
  },
  components: {
    ListItem,
  },
  computed: {
    ...mapGetters('stock', [
      'showCategoriesSection',
      'showCompositionSection',
      'showColorsSection',
      'showFactorSection',
      'showVendorsSection',
      'showPrintsSection',
      'showNcmSection',
      'showProductsSection',
      'showDashboardSection',
    ]),
  },
}
</script>
