<template>
  <v-list-item
    class="my-3 pl-3"
    :to="route"
  >
    <v-list-item-icon
      class="mr-3"
      style="margin-left: 2px"
    >
      <v-icon
        size="20"
        dense
      >
        {{ icon }}
      </v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>{{ title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    route: String,
    icon: String,
    title: String,
  },
}
</script>
